<template>
  <div class="checkout mt-4">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <!-- <Breadcrumb class="hidden-sm-and-down px-0" :items="breadcrumbs" /> -->

    <PaymentStepper :step="1" class="d-flex d-md-none mb-2" />

    <v-container fluid class="pt-0">
      <v-row no-gutters>
        <!-- visualizzazione desktop -->
        <v-col
          class="summary-cards d-flex flex-column mt-4 mt-sm-0"
          cols="12"
          md="3"
          order="0"
          order-md="1"
        >
          <div class="filter-sticky-column d-flex flex-column">
            <CartInfoAddressCard
              class="mb-2 order-2 order-md-1"
              :shippingAddress="cart.shippingAddress"
              :editable="editableAddress"
              @edit="openAddressSelector"
              :isCheckout="true"
              :small="true"
            />
            <CartInfoTimeslotCard
              class="mb-2 order-3 order-md-2"
              :shippingAddress="cart.shippingAddress"
              :timeslot="cart.timeslot"
              :editable="editableTimeslot"
              @edit="handleEditTimeslot"
              :isCheckout="false"
              :small="true"
            />

            <ebsn-meta
              class="order-1 order-md-0 d-block wp-text"
              :target="category"
              path="category_info.FOOTER_TEXT"
              tag="div"
            />

            <GiftCode
              v-if="cart.totalItems > 0"
              class="mb-0 py-2 px-4 order-4 order-md-6"
              :giftCertificatesReceived="giftCertificatesReceived"
              @addGiftCode="addUserGiftCode"
              @sendGiftCode="sendUserGiftCode"
            />
            <!-- <DeliveryFee
              v-if="deliveryFee"
              :showList="true"
              :deliveryFee="deliveryFee"
              :class="{ 'd-none': !isShowingDeliveryFee }"
              class="order-0 order-md-4 mb-4 mb-md-2"
              @changeShowingDeliveryFee="changeShowingDeliveryFee"
            /> -->
            <CheckoutSummary
              class="order-0 order-md-4 mb-4 mb-md-6"
              :orderCart="cart"
              @removeGiftCode="removeUserGiftCode"
            />
            <category-block
              :target="category"
              position="position3"
              class="category-block category-block-3 order-md-5"
            />
            <div
              class="payment-btn-wrapper  d-none d-md-flex"
              v-if="cart.totalItems > 0"
            >
              <v-btn
                color="secondary"
                x-large
                block
                @click="confirmOrderData"
                class="mt-0 mt-sm-3 mb-4"
                depressed
                :loading="loading"
              >
                {{ $t("cart.button.goToPayment") }}
              </v-btn>
            </div>

            <category-block
              :target="category"
              position="position4"
              class="category-block category-block-4 order-8"
            />
          </div>
        </v-col>
        <v-col
          cols="12"
          md="9"
          order="1"
          order-md="0"
          class="cart-info pa-0 pa-sm-3 pr-sm-9"
        >
          <PaymentStepper :step="1" class="d-none d-md-flex" />
          <category-title :category="category" />
          <!-- <v-card-text class="pa-0">
            <p class="default--text" v-html="$t('checkout.easydrivePdv')"></p
          ></v-card-text> -->

          <packages-type-list class="my-7" />
          <GiftListSlider
            :key="renderKey"
            :timeslotId="cart.timeslot.date + cart.timeslot.timeslotId"
            :userId="cart.user.userId"
            :warehouseId="cart.warehouse.warehouseId"
            :layout="36"
            :limit="24"
            :lg="3"
            :xl="4"
            :paginationClass="'swiper-pagination-linea-220'"
            :showArrows="!$vuetify.breakpoint.xsOnly"
            :showBullets="$vuetify.breakpoint.xsOnly"
          />

          <CartItemList
            :full="true"
            :showDescrWeight="false"
            :isCheckout="true"
            :hideAcceptSubstitutes="!enableAcceptSubstitutes"
            :productsAlternatives="productsAlternatives"
          />

          <v-col cols="12" class="pt-0">
            <v-alert
              v-if="error"
              type="error"
              border="left"
              transition="v-fab-transition"
            >
              {{ error }}
            </v-alert>
            <div class="text-body-2 mt-4 mb-2" v-if="cart.totalItems > 0">
              {{ $t("checkout.disclaimer") }}
            </div>
            <v-row no-gutters class="checkout-actions">
              <v-col cols="12" class="py-2">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-btn
                      x-large
                      block
                      :outlined="!$vuetify.breakpoint.xs"
                      :depressed="!$vuetify.breakpoint.xs"
                      :text="$vuetify.breakpoint.xs"
                      :color="$vuetify.breakpoint.xs ? 'primary' : 'black'"
                      class="mr-3"
                      to="/"
                    >
                      <v-icon small class="mr-2">$chevronLeft</v-icon>
                      {{ $t("cart.button.continue") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="!hasStripePayment">
                    <v-btn
                      block
                      color="primary lighten-1"
                      x-large
                      depressed
                      :disabled="cart.totalItems == 0"
                      @click="fastPayment"
                    >
                      <span>{{ $t("checkout.fastPaymentBtn") }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="hasStripePayment">
                    <v-btn
                      v-if="cart.totalItems > 0"
                      x-large
                      block
                      outlined
                      depressed
                      class="col-sm-6"
                      :disabled="cart.totalItems == 0"
                      @click="addAllToFavorites"
                    >
                      <v-icon small class="mr-2">$heart</v-icon>
                      {{ $t("cart.button.addToFavorites") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="py-2">
                <v-row>
                  <v-col cols="12" sm="6" v-if="!hasStripePayment">
                    <v-btn
                      v-if="cart.totalItems > 0"
                      x-large
                      block
                      outlined
                      depressed
                      class="col-sm-6"
                      :disabled="cart.totalItems == 0"
                      @click="addAllToFavorites"
                    >
                      <v-icon small class="mr-2">$heart</v-icon>
                      {{ $t("cart.button.addToFavorites") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-btn
                      v-if="cart.cartItems.length > 0 && cart.totalItems > 0"
                      color="primary"
                      text
                      block
                      x-large
                      @click="emptyCart(cart.cartItems)"
                      class="empty-cart mr-3 font-weight-bold col-sm-6"
                      >{{ $t("cart.button.emptyCart") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-col>
      </v-row>
      <!-- button fisso posizionato a fondo pagina per dispositivi sm-and-down -->
      <div
        v-if="cart.totalItems > 0"
        class="payment-btn-wrapper fixed order-last d-flex d-md-none"
      >
        <v-btn
          color="secondary"
          x-large
          block
          @click="confirmOrderData"
          class="mt-0 mt-sm-3"
          depressed
          :loading="loading"
        >
          {{ $t("cart.button.goToPayment") }}
        </v-btn>
      </div>
    </v-container>

    <v-spacer class="spacer-scroll"></v-spacer>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style scoped lang="scss">
.cart {
  position: relative;
  padding-bottom: 56px;
}
.payment-btn-wrapper {
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    position: fixed;

    background: var(--v-primary-base);
    margin: 0 -12px;
    padding: 5px;
    &.fixed {
      width: 100%;
      bottom: 0px;
      z-index: 9;
    }
  }
}
.wp-text {
  width: 100%;
  :deep(p) {
    margin-bottom: 20px;
    span {
      display: block;
    }
  }
}
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import GiftCode from "@/components/GiftCode.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import GiftListSlider from "@/components/product/GiftListSlider.vue";
import FastPayment from "@/components/cart/FastPayment.vue";
//import eurospinCustomService from "@/service/eurospinCustomService";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PackagesTypeList from "@/components/payment/PackagesTypeList.vue";
import eurospinCustomService from "@/service/eurospinCustomService";
import RegistrationService from "~/service/userService";
import ListService from "~/service/listService";
import AnalyticsService from "~/service/analyticsService";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import ConfirmOrderData from "./ConfirmOrderData";

import { mapActions, mapState, mapGetters } from "vuex";
import { forEachCartItem } from "~/service/ebsn";
// import DeliveryFee from "@/components/delivery/DeliveryFee.vue";
import DeliveryService from "~/service/deliveryService";
import PaymentTypeService from "~/service/paymentTypeService";

export default {
  name: "Checkout",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  components: {
    CartItemList,
    CheckoutSummary,
    GiftCode,
    PaymentStepper,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CategoryTitle,
    PackagesTypeList,
    GiftListSlider
    // DeliveryFee
  },
  data() {
    return {
      dialogInstance: null,
      error: null,
      loading: false,
      giftCertificatesReceived: [],
      emailKey: 1,
      showAlternativesTooltip: false,
      renderKey: 1,
      deliveryFee: null,
      isShowingDeliveryFee: true,
      productsAlternatives: {},
      products: [],
      totalAmountReached: 0,
      paymentTypeList: [],
      componentKey: 0
    };
  },
  computed: {
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "Checkout",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getCartStatus: "cart/getCartStatus",
      user: "cart/getUser"
    }),
    enableAcceptSubstitutes() {
      return !this.cart.warehouse?.metaData?.warehouse_info?.HIDE_SUBSTITUTE;
    },
    hasStripePayment() {
      return this.paymentTypeList.some(
        paymentType =>
          paymentType.paymentTypeId === 25 && paymentType.active === 1
      );
    }
  },
  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      addGiftCode: "cart/addGiftCode",
      sendGiftCode: "cart/sendGiftCode",
      removeGiftCode: "cart/removeGiftCode"
    }),
    changeShowingDeliveryFee(isShowing) {
      this.isShowingDeliveryFee = isShowing;
    },
    async fastPayment() {
      let _this = this;
      let res = await _this.$dialog.show(FastPayment, {
        waitForResult: true,
        width: 500
      });
      if (res) {
        // let items = [];
        // forEachCartItem(_this.cart, function(item) {
        //   items.push(item);
        // });
        // await ListService.addProductsToList(res.listId, items);
      }
    },
    async addUserGiftCode(giftCodeId) {
      let cart = await this.addGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async sendUserGiftCode(giftCodeId) {
      let cart = await this.sendGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async removeUserGiftCode(giftCodeId) {
      let cart = await this.removeGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async getUserDetails() {
      const data = await RegistrationService.getUserDetail();
      this.giftCertificatesReceived = data ? data.giftCertificatesReceived : [];
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.xs,
        width: 300
      });
      // let newQuantity = CartService.plus(vm.product, vm.quantity);
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },

    async confirmOrderData() {
      if ([1, 4, 2].includes(this.cart.shippingAddress.deliveryServiceId)) {
        let config = {
          width: 600
        };
        this.dialogInstance = await this.$dialog.show(ConfirmOrderData, config);
        const result = await this.dialogInstance.wait();
        if (typeof result === "undefined") {
          return;
        }
        if (result) {
          this.goToPayment();
        } else {
          this.openAddressSelector();
        }
      } else {
        this.goToPayment();
      }
    },
    async handleEditTimeslot() {
      await this.openTimeslotSelector();
      this.renderKey++;
    },
    async goToPayment() {
      let vm = this;
      vm.loading = true;
      try {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            await vm.lockCart();
            vm.$router.push({
              name: "Payment"
            });
          }
        }
      } catch (err) {
        await this.needTimeslot();
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    fetchPaymentTypeList() {
      PaymentTypeService.getPaymentTypeList(this.orderId, true).then(
        paymentTypeList => {
          this.paymentTypeList = this.filteredPaymentTypeIds
            ? paymentTypeList.filter(paymentType =>
                this.filteredPaymentTypeIds.includes(paymentType.paymentTypeId)
              )
            : paymentTypeList;
        }
      );
    }
  },
  async created() {
    global.EventBus.$on("timeslotchanged", () => {
      this.renderKey++;
    });
    this.fetchPaymentTypeList();
  },
  async mounted() {
    AnalyticsService.beginCheckout(this.cart);
    this.giftCertificatesReceived = this.cart.user.giftCertificatesReceived
      ? this.cart.user.giftCertificatesReceived
      : [];
    //product alternatives
    let productIds = [];

    for (let cartItem of this.cart.cartItems) {
      productIds.push(parseInt(cartItem.product.productId));
    }
    let productIdsParam = productIds.join(",");

    let res = await eurospinCustomService.getProductAlternativeList(
      productIdsParam,
      true
    );
    if (res?.alternatives) {
      this.productsAlternatives = res.alternatives;
    }
    this.deliveryFee = await DeliveryService.getDeliveryFee();
  },
  watch: {
    "cart.warehouse.warehouseId": function() {
      this.fetchPaymentTypeList();
    }
  }
};
</script>
